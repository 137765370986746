var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"text-align":"center","height":"100vh","width":"100vw"}},[_c('div',{staticClass:"flex-v",style:({
      width: '100%',
      height: '100%',
      'background-image': 'url(' + _vm.picUrl + ')',
      'background-repeat': 'no-repeat',
      'background-size': '100% 100%',
      '-moz-background-size': '100% 100%',
    })},[_c('div',{staticStyle:{"padding-left":"10%","padding-right":"10%","padding-top":"8%"}},[_c('div',{staticStyle:{"padding-bottom":"6px"}},[_c('van-row',[_c('van-col',{attrs:{"span":"1"}}),_c('van-col',{attrs:{"span":"22"}},[_c('div',{style:({
                width: '100%',
                'background-image': 'url(' + _vm.cardBg + ')',
                'background-repeat': 'no-repeat',
                'background-size': '100% 100%',
                '-moz-background-size': '100% 100%',
              })},[_c('div',{staticStyle:{"height":"300px","padding-top":"30px","display":"flex","align-items":"center","flex-direction":"column"}},[_c('van-image',{staticStyle:{"border":"2px solid white","border-radius":"4px"},attrs:{"height":"160","width":"80%","src":_vm.buildingList[_vm.choose].pic}}),_c('div',{staticStyle:{"color":"white","line-height":"30px","font-weight":"600"}},[_vm._v(" "+_vm._s(_vm.buildingList[_vm.choose].title)+" ")])],1)])]),_c('van-col',{attrs:{"span":"1"}})],1),_c('div',{staticStyle:{"line-height":"40px","color":"white","font-size":"13px","font-weight":"500"}},[_vm._v(" 点击右上角，送卡给好友 ")]),_c('van-button',{attrs:{"round":"","type":"info"},on:{"click":_vm.back}},[_vm._v("返回")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }